<template>
  <n-modal
    v-model:show="isActive"
    preset="dialog"
    :title="modalRef.header"
    :content="modalRef.body"
    positive-text="Accept"
    negative-text="Cancel"
    @positive-click="submitCallback"
  />
</template>

<script>
import { accept, isActive, modalRef } from '@/app/use/modal';

export default {
  name: 'Modal',
  setup() {
    const submitCallback = () => {
      accept();
    };

    return {
      isActive,
      submitCallback,
      modalRef,
    };
  },
};
</script>

<style scoped lang="scss"></style>
