import axios from 'axios';
import { getApiUrl } from '@/app/helpers/axios';
import { error as showErrorSnackbar } from '@/app/use/snackbar';
import { useUser } from '@/app/use/user';
import {
  getAccessToken,
  getRefreshToken,
  isTokenValid,
  saveTokensInLocalStorage,
} from '@/app/helpers/auth';
import { refreshTokenRequest } from '@/app/api/auth';

export const http = axios.create({
  baseURL: getApiUrl(),
});

export const auth = axios.create({
  baseURL: `${getApiUrl()}/auth`,
});

// Response interceptor
http.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      const { logout } = useUser();
      logout();
    } else if (error.response?.status === 403) {
      showErrorSnackbar("You don't have permissions to do that");
    } else {
      showErrorSnackbar(error.response?.data?.message || error.message || 'Something went wrong');
    }

    console.error(error.response);

    return Promise.reject(error);
  }
);

// Request interceptor
http.interceptors.request.use(config => {
  // eslint-disable-next-line no-prototype-builtins
  if (config.headers.hasOwnProperty('Authorization')) {
    return config;
  }

  const authorizationToken = getAccessToken();
  if (isTokenValid(authorizationToken)) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${authorizationToken}`;

    return config;
  }

  const refreshToken = getRefreshToken();
  if (isTokenValid(refreshToken)) {
    const { setUserData } = useUser();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${refreshToken}`;
    refreshTokenRequest(refreshToken).then(newTokens => {
      saveTokensInLocalStorage(newTokens);
      setUserData({ tokens: newTokens });
    });
  }

  return config;
});

// Response interceptor
auth.interceptors.response.use(
  response => response,
  error => {
    showErrorSnackbar(error.response?.data?.message || error.message || 'Something went wrong');
    console.error(error);
    return Promise.reject(error);
  }
);
