<template>
  <n-modal v-model:show="isLoadingActive" :mask-closable="false" size="small">
    <n-card
      style="width: 175px"
      content-style="font-size: 18px;"
      :bordered="false"
      :segmented="false"
      size="medium"
      role="dialog"
      aria-modal="true"
    >
      <n-spin size="20" />
      Processing...
    </n-card>
  </n-modal>
</template>

<script>
import { isLoadingActive } from '@/app/use/loadingModal';

export default {
  name: 'LoadingModal',
  setup() {
    return {
      isLoadingActive,
    };
  },
};
</script>

<style scoped lang="scss"></style>
