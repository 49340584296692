import { createApp } from 'vue';
import App from './App';
import router from './router';
import naive from 'naive-ui';

import '@/assets/styles.scss';
import { clickOutside } from '@/app/helpers/click-outside';

const app = createApp(App);

app.use(router);
app.use(naive);
app.directive('click-outside', clickOutside);
app.mount('#app');
