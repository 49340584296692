<template>
  <transition name="bounce">
    <div v-if="snackbarVisible" class="snackbar-box" :class="snackbar.mode">
      {{ snackbar.message }}
      <Icon name="close" class="close-icon clickable-item" @click="snackbarVisible = false" />
    </div>
  </transition>
</template>

<script>
import { snackbar, snackbarVisible } from '@/app/components/snackbar/SnackbarBus';
import Icon from '@/app/components/base/Icon';

export default {
  components: { Icon },
  setup() {
    return { snackbarVisible, snackbar };
  },
};
</script>

<style lang="scss">
.snackbar-box {
  margin: 0 auto;
  bottom: 5%;
  left: 5%;
  right: 5%;
  max-width: 500px;
  padding: 1rem;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  background-color: #323232;
  color: #fff;
  border-radius: 6px;
  z-index: 10000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);

  &.error {
    background-color: var(--error-color);
  }

  &.success {
    background-color: var(--success-color);
  }

  &.warning {
    background-color: var(--warning-color);
  }

  &.info {
    background-color: var(--info-color);
  }

  .close-icon {
    padding: 2px;
    border-radius: 6px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
