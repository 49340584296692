import { createRouter, createWebHistory, useRoute } from 'vue-router';
import { routes } from '@/app/router/routes';
import { setDocumentTitle } from '@/app/helpers/document-title';
import { useUser } from '@/app/use/user';
import { getAccessToken, getRefreshToken, isTokenValid } from '@/app/helpers/auth';
import { refreshTokenRequest } from '@/app/api/auth';
import { computed } from 'vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  setDocumentTitle(to.meta.title);
  const { isLogged, setUserData, login, logout } = useUser();
  if (isLogged.value || to.name === 'Login') {
    next();
    return;
  }

  if (to.name !== 'Login') {
    setUserData({ requestedRoute: to });
  }

  const access_token = getAccessToken();
  const refresh_token = getRefreshToken();

  if (isTokenValid(access_token)) {
    login({ access_token, refresh_token });
    return;
  }

  if (isTokenValid(refresh_token)) {
    refreshTokenRequest(refresh_token)
      .then(tokens => {
        login(tokens);
        next(to);
      })
      .catch(() => {
        logout();
        next('/login');
      });
    return;
  }

  next('/login');
});

export const redirectToRoute = route => {
  router.push(route).catch(() => {
    /* */
  });
};

export const useUrl = () => {
  const route = useRoute();

  const id = computed(() => route.params.id);
  const currentPath = computed(() => route.path);
  const currentRouteName = computed(() => router.currentRoute.value.name);

  return {
    id,
    currentRouteName,
    currentPath,
  };
};

export const reload = () => {
  redirectToRoute(useUrl().currentPath);
};

export default router;
