import { useStore } from '@/app/use/store';
import { computed } from 'vue';

const DEFAULT_MENU_SETTINGS = {
  isMinimalized: false,
};
const { store, setData } = useStore(DEFAULT_MENU_SETTINGS);

export const useNavigation = () => {
  const isMenuMinimalized = computed(() => store.value.isMinimalized);

  const toggleMenu = () => {
    const menuObject = {
      isMinimalized: !isMenuMinimalized.value,
    };
    setData(menuObject);
  };

  return { toggleMenu, isMenuMinimalized };
};
