export const routes = [
  {
    path: '/',
    redirect: '/subscriptions',
    name: 'Home',
    meta: {
      title: 'Home',
    },
    component: () => import(/* webpackChunkName: "home" */ '@/home/views/ViewHome.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
    },
    component: () => import(/* webpackChunkName: "login" */ '@/login/views/ViewLogin.vue'),
  },
  {
    path: '/subscriptions',
    name: 'SubscriptionsSearch',
    meta: {
      title: 'Subscriptions Search',
    },
    component: () =>
      import(/* webpackChunkName: "Subscriptions" */ '@/views/Subscription/SubscriptionsList.vue'),
  },
  {
    path: '/subscriptions/:id',
    name: 'Subscription',
    meta: {
      title: 'Subscription',
    },
    component: () =>
      import(/* webpackChunkName: "Subscription" */ '@/views/Subscription/SubscriptionDetails.vue'),
  },
  {
    path: '/payments/:id',
    name: 'Payment',
    meta: {
      title: 'Payment',
    },
    component: () => import(/* webpackChunkName: "Payment" */ '@/views/Payment/PaymentDetails.vue'),
  },
  {
    path: '/emails',
    name: 'EmailsSearch',
    meta: {
      title: 'Emails Search',
    },
    component: () => import(/* webpackChunkName: "Emails" */ '@/views/Email/EmailsList.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' },
  },
];
