import { ref } from 'vue';

export const useStore = (defaultStore = {}) => {
  const store = ref(defaultStore);

  const setData = data => {
    store.value = { ...store.value, ...data };
  };

  const reset = () => {
    store.value = defaultStore;
  };

  return {
    store,
    setData,
    reset,
  };
};
