<template>
  <div class="sub-menu">
    <div class="sub-menu-item-wrapper unselectable" :style="{ height: subMenuHeight }">
      <router-link
        v-for="item in subMenuItems"
        :key="item.title"
        class="sub-menu-item clickable-item"
        :class="{ minimalized: isMenuMinimalized }"
        :to="item.to ? item.to : ''"
        @click="item.click"
      >
        <Icon :name="item.icon" />
        <p class="title">{{ item.title }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/app/components/base/Icon';
import { useNavigation } from '@/app/use/navigation';
import { computed } from 'vue';

export default {
  name: 'SubMenuItems',
  components: { Icon },
  props: {
    subMenuItems: {
      type: Object,
      required: true,
    },
    isMenuVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const { isMenuMinimalized } = useNavigation();
    const subMenuHeight = computed(() =>
      props.isMenuVisible ? 42 * props.subMenuItems.length + 'px' : 0
    );

    return { isMenuMinimalized, subMenuHeight };
  },
};
</script>

<style scoped lang="scss">
.sub-menu {
  overflow: hidden;
}

.sub-menu-item-wrapper {
  transition: height 0.2s;

  .sub-menu-item {
    height: 42px;
    padding-left: 3rem;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffff;
    transition: padding-left 0.2s;

    .title {
      padding-left: 1rem;
    }

    &.minimalized {
      padding-left: 0;
      flex-direction: column;
      justify-content: space-around;
    }

    &.minimalized .title {
      font-size: 8px;
      line-height: 8px;
      padding-left: 0;
      text-align: center;
    }
  }
}
</style>
