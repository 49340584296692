<template>
  <div class="hamburger-menu">
    <Icon class="hamburger-icon clickable-item" name="menu" size="30" @click="toggleMenu" />
  </div>
</template>

<script>
import { useNavigation } from '@/app/use/navigation';
import Icon from '@/app/components/base/Icon';

export default {
  name: 'TheHamburgerMenu',
  components: { Icon },
  setup() {
    const { toggleMenu } = useNavigation();

    return { toggleMenu };
  },
};
</script>

<style scoped lang="scss">
.hamburger-menu {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  min-width: var(--navigation-icons-width);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger-icon {
  padding: 6px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}
</style>
