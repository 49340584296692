import { addSnackbar } from '@/app/components/snackbar/SnackbarBus';

export const snackbar = config => {
  addSnackbar(config);
};

export const info = message => {
  addSnackbar({ message, mode: 'info' });
};

export const error = message => {
  addSnackbar({ message, mode: 'error', timeout: 0 });
};

export const success = (message, timeout = 3000) => {
  addSnackbar({ message, mode: 'success', timeout });
};

export const warn = (message, timeout = 3000) => {
  addSnackbar({ message, mode: 'warning', timeout });
};
