import { ref } from 'vue';

const DEFAULT_MODAL = {
  onAccept: () => {},
  onCancel: () => {},
  mode: 'info',
  header: 'Info',
  body: 'Modal body',
};

export const modalRef = ref(DEFAULT_MODAL);
export const isActive = ref(false);

export const accept = () => {
  modalRef.value.onAccept();
  isActive.value = false;
  modalRef.value = DEFAULT_MODAL;
};

export const cancel = () => {
  modalRef.value.onCancel();
  isActive.value = false;
  modalRef.value = DEFAULT_MODAL;
};

export const useModal = ({
  onAccept = () => {},
  onCancel = () => {},
  mode = 'info',
  header = 'Info',
  body = 'Modal body',
}) => {
  const show = (...args) => {
    modalRef.value = {
      header,
      body,
      mode,
      onAccept: onAccept.bind(null, ...args),
      onCancel,
    };
    isActive.value = true;
  };

  const hide = () => {
    isActive.value = false;
    modalRef.value = DEFAULT_MODAL;
  };

  return { show, hide };
};
