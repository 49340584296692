<template>
  <header :class="{ 'login-screen': !isLogged }">
    <the-hamburger-menu v-if="isLogged" />
    <p class="title">Customer Care / {{ currentRouteName }}</p>
    <div
      v-if="isLogged"
      class="user-wrapper clickable-item unselectable"
      :class="{ 'menu-visible': isUserMenuVisible }"
      @click="toggleUserMenu"
      @mouseleave="isUserMenuVisible = false"
    >
      <div class="logged-user">
        <Icon class="user-icon" name="account_box" />
        {{ userName }}
      </div>
      <transition name="show-user-menu">
        <ul v-if="isUserMenuVisible" class="user-menu">
          <li
            v-for="item in userMenu"
            :key="item.title"
            class="user-menu-item"
            @click="item.action"
          >
            {{ item.title }}
          </li>
        </ul>
      </transition>
    </div>
  </header>
</template>

<script>
import { ref } from 'vue';
import Icon from '@/app/components/base/Icon';
import TheHamburgerMenu from '@/app/components/TheHamburgerMenu';
import { useUser } from '@/app/use/user';
import { useUrl } from '@/app/router';

export default {
  name: 'TheHeader',
  components: { TheHamburgerMenu, Icon },
  setup() {
    const { isLogged, logout, userName } = useUser();
    const { currentRouteName } = useUrl();
    const isUserMenuVisible = ref(false);
    const userMenu = [
      {
        title: 'Logout',
        action: logout,
      },
    ];

    const toggleUserMenu = () => (isUserMenuVisible.value = !isUserMenuVisible.value);

    return {
      currentRouteName,
      isLogged,
      logout,
      userName,
      isUserMenuVisible,
      toggleUserMenu,
      userMenu,
    };
  },
};
</script>

<style scoped lang="scss">
header {
  position: fixed;
  height: var(--header-height);
  width: 100%;
  padding: 0 50px 0 calc(50px + var(--navigation-icons-width));
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  .title {
    color: #ffffff;
    font-size: 1.5rem;
  }

  .user-wrapper {
    position: relative;
    color: #fff;
    border-radius: 6px;

    &.menu-visible {
      border-radius: 6px 6px 0 0;
    }

    .logged-user {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 1.1rem;
      letter-spacing: 0.5px;
      padding: 0.5rem 1rem;
    }

    .user-icon {
      margin-right: 5px;
    }

    .user-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: var(--primary-color);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
      border-radius: 0 0 6px 6px;
    }

    .user-menu-item {
      padding: 0.5rem;
      font-size: 1rem;
      list-style: none;

      &:last-child {
        border-radius: 0 0 6px 6px;
      }

      &:hover {
        background-color: var(--secondary-color);
      }
    }
  }

  &.login-screen {
    padding-left: var(--navigation-icons-width);
  }
}

.show-user-menu-enter-active {
  animation: show-user-menu 0.2s;
}
.show-user-menu-leave-active {
  animation: show-user-menu 0.2s reverse;
}

@keyframes show-user-menu {
  from {
    opacity: 0.2;
    transform: scaleY(0) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
}

@media (max-width: 767px) {
  header {
    flex-direction: column;
    padding: 5px 0;

    .title {
      margin-left: 5px;
      font-size: 1rem;
    }

    .user-wrapper {
      .logged-user {
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
      }

      .user-menu-item {
        padding: 0.4rem;
        font-size: 0.8rem;
      }
    }

    &.login-screen {
      padding-left: 0;
      justify-content: center;
    }
  }
}
</style>
