import { computed, ref, watchEffect } from 'vue';

const DEFAULT_SNACKBAR = {
  mode: 'info',
  message: '',
  timeout: 4000,
};

export const queue = ref([]);
export const snackbar = ref({});
export const snackbarVisible = ref(false);

const hasNotificationsPending = computed(() => queue.value.length > 0);

const showSnackbar = config => {
  snackbar.value = {
    ...DEFAULT_SNACKBAR,
    ...config,
  };
};

const hideSnackbar = () => {
  if (snackbar.value.timeout === 0) return;

  setTimeout(() => {
    snackbarVisible.value = false;
  }, snackbar.value.timeout);
};

watchEffect(() => {
  if (!snackbarVisible.value && hasNotificationsPending.value) {
    setTimeout(() => {
      showSnackbar(queue.value.shift());
      snackbarVisible.value = true;
    });
  }

  if (snackbarVisible.value) {
    hideSnackbar();
  }
});

export const addSnackbar = config => {
  queue.value.push(config);
};
