<template>
  <div v-if="isLogged" class="navigation-menu" :class="{ minimalized: isMenuMinimalized }">
    <div v-for="item in allowedMenuItems" :key="item.title" class="menu-wrapper">
      <MainMenuItem :menu-item="item" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useUser } from '@/app/use/user';
import MainMenuItem from '@/app/components/menu/MainMenuItem';
import { useNavigation } from '@/app/use/navigation';
import { userHasPermission } from '@/app/helpers/permissions';

export default {
  name: 'TheNavigation',
  components: { MainMenuItem },
  setup() {
    const { isLogged, logout } = useUser();
    const { isMenuMinimalized } = useNavigation();

    const menuItems = [
      {
        title: 'Subscriptions',
        icon: 'card_membership',
        to: '/subscriptions',
        requiredPermission: 'CUSTOMER_CARE',
      },
      {
        title: 'Emails',
        icon: 'mail',
        to: '/emails',
        requiredPermission: 'EMAIL_READ',
      },
    ];

    const allowedMenuItems = computed(() =>
      menuItems.filter(
        item =>
          !item.requiredPermission ||
          userHasPermission(item.requiredPermission)
      )
    );

    return { isLogged, logout, isMenuMinimalized, allowedMenuItems };
  },
};
</script>

<style scoped lang="scss">
.navigation-menu {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  width: var(--navigation-width);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: var(--primary-color);
  color: #ffffff;
  justify-content: space-between;
  transition: width 0.2s;
  animation: show-navigation 0.2s;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.minimalized {
    width: var(--navigation-icons-width);
  }

  .menu-wrapper {
    border-bottom: 1px solid var(--secondary-color);
  }

  @keyframes show-navigation {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
}

@media (max-width: 767px) {
  .navigation-menu {
    transition: transform 0.2s;

    &.minimalized {
      width: var(--navigation-width);
      transform: translateX(-100%);
    }
  }
}
</style>
