<template>
  <div
    class="menu-item clickable-item unselectable"
    :class="{ 'router-link-active': currentPath === menuItem.to }"
    @click="onAction()"
  >
    <Icon :name="menuItem.icon" size="36" />
    <div v-if="!isMenuMinimalized" class="item-title-wrapper">
      <p class="title">{{ menuItem.title }}</p>
      <Icon
        v-if="menuItem.children"
        class="arrow-icon"
        :class="{ expand: isVisibleSubMenu }"
        name="expand_more"
      />
    </div>
  </div>

  <SubMenuItems
    v-if="menuItem.children"
    :is-menu-visible="menuItem.children && isVisibleSubMenu"
    :sub-menu-items="menuItem.children"
  />
</template>

<script>
import { ref } from 'vue';
import Icon from '@/app/components/base/Icon';
import SubMenuItems from '@/app/components/menu/SubMenuItems';
import { useNavigation } from '@/app/use/navigation';
import { redirectToRoute, useUrl } from '@/app/router';

export default {
  name: 'MainMenuItem',
  components: { SubMenuItems, Icon },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isVisibleSubMenu = ref(false);
    const { currentPath } = useUrl();
    const { isMenuMinimalized } = useNavigation();

    const onAction = () => {
      if (props.menuItem.children) {
        isVisibleSubMenu.value = !isVisibleSubMenu.value;
        return;
      }

      redirectToRoute(props.menuItem.to);
    };

    return { onAction, isVisibleSubMenu, currentPath, isMenuMinimalized };
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  height: var(--navigation-icons-width);
  padding: 0 10px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.item-title-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.arrow-icon {
  transform: rotate(0);
  transition: transform 0.2s;

  &.expand {
    transform: rotate(-180deg);
  }
}

.title {
  padding-left: 1rem;
  flex: 1;
}

.menu-item-hyperlink {
  display: block;
  text-decoration: none;
  color: #ffff;
}
</style>
