<template>
  <span class="material-icons unselectable" :style="{ 'font-size': `${size}px` }">{{ name }}</span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
  },
};
</script>

<style scoped lang="scss">
.material-icons {
  cursor: pointer;
}
</style>
