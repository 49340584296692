import { ref } from 'vue';

export const isLoadingActive = ref(false);

export const useLoadingModal = () => {
  const showLoading = () => {
    isLoadingActive.value = true;
  };

  const hideLoading = () => {
    isLoadingActive.value = false;
  };

  return { showLoading, hideLoading };
};
