<template>
  <TheHeader />
  <TheNavigation />
  <div
    class="content-wrapper"
    :class="{
      'menu-minimalized': isMenuMinimalized,
      login: route.path === '/login',
    }"
  >
    <router-view />
  </div>
  <Snackbar />
  <Modal />
  <LoadingModal />
</template>

<script>
import TheHeader from '@/app/components/TheHeader';
import TheNavigation from '@/app/components/TheNavigation';
import Snackbar from '@/app/components/snackbar/Snackbar';
import Modal from '@/app/components/base/Modal';
import LoadingModal from '@/app/components/base/LoadingModal';
import { useNavigation } from '@/app/use/navigation';
import { useRoute } from 'vue-router';

export default {
  components: { Modal, Snackbar, TheNavigation, TheHeader, LoadingModal },
  setup() {
    const { isMenuMinimalized } = useNavigation();
    const route = useRoute();

    return { isMenuMinimalized, route };
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
  top: var(--header-height);
  width: calc(100% - var(--navigation-width));
  overflow-x: hidden;
  min-height: calc(100vh - var(--header-height));
  transform: translateX(var(--navigation-width));
  transition: transform 0.2s, width 0.2s;
  padding-bottom: 300px;

  &.menu-minimalized {
    transform: translateX(56px);
    width: calc(100% - var(--navigation-icons-width));
  }

  &.login {
    width: 100%;
    margin: 0 auto;
    transform: none;
  }
}

@media (max-width: 767px) {
  .content-wrapper {
    &.menu-minimalized {
      width: 100%;
      transform: none;
    }
  }
}
</style>
