export const clickOutside = {
  created(el, binding) {
    const handler = e => {
      if (!el.contains(e.target)) {
        el.__vueClickOutside__.callback(e);
      }
    };

    // eslint-disable-next-line no-param-reassign
    el.__vueClickOutside__ = {
      handler: handler,
      callback: binding.value,
    };

    document.addEventListener('click', handler);
  },
  unmounted(el) {
    document.removeEventListener('click', el.__vueClickOutside__.handler);
    // eslint-disable-next-line no-param-reassign
    delete el.__vueClickOutside__;
  },
};
