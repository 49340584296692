import decode from 'jwt-decode';
import { useUser } from '@/app/use/user';

export const saveTokensInLocalStorage = ({ access_token, refresh_token }) => {
  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('refreshToken', refresh_token);
};

const getAccessTokenFromLocalStorage = () => localStorage.getItem('accessToken');

const getRefreshTokenFromLocalStorage = () => localStorage.getItem('refreshToken');

export const removeTokensFromLocalStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const getAccessToken = () => {
  const { accessToken } = useUser();
  return accessToken.value || getAccessTokenFromLocalStorage();
};

export const getRefreshToken = () => {
  const { refreshToken } = useUser();
  return refreshToken.value || getRefreshTokenFromLocalStorage();
};

export const setTokens = tokens => {
  const { setUserData } = useUser();
  saveTokensInLocalStorage(tokens);
  setUserData({ tokens });
};

export const isTokenValid = token => {
  if (!token) {
    return false;
  }
  try {
    const decodedToken = decode(token);
    const tokenExpirationDate = decodedToken.exp;
    const nowDate = Math.floor(Date.now() / 1000);

    return tokenExpirationDate > (nowDate + 5);
  } catch (err) {
    return false;
  }
};
