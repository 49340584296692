import { auth } from '@/app/helpers/axios-instance';

export const loginRequest = ({ username, password }) =>
  auth.post('/login', { username, password }).then(response => response.data);

export const refreshTokenRequest = refreshToken =>
  auth
    .post('/refresh', null, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    })
    .then(response => response.data);
